import {
  Button,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'

import LayersIcon from '@material-ui/icons/Layers'
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import {
  createLayerFormAction,
  setActiveLayer,
  setRecords,
} from 'store/layers/layer.actions'
import { FormMode, ILayer } from 'global'
import clsx from 'clsx'
import { Hide } from 'components/Hide'
import { LayerFormAction } from 'store/layers/layer.reducer'

import { deleteLayer, fetchLayers } from 'store/layers'
import useOrg from 'hooks/useOrg'
import useLayer from 'hooks/useLayer'
import { goToLayer } from 'utils/common'
import ReportView from './ReportView'
import RecordActions from './RecordActions'
import useStyle from './LeftComponent.style'

function LeftComponent() {
  const classes = useStyle()
  const dispatch = useDispatch()
  const [reportView, toggleReportView] = useState(false)
  const { activeOrg } = useOrg()
  const { userCan } = useLayer()
  const { layer } = useSelector((state: RootState) => state)
  const {
    layers,
    selectedLayer,
    selectdRecord,
    recordFormMode,
    loadingLayers,
  } = layer
  const isActiveRecord =
    (!!selectdRecord && !!Object.keys(selectdRecord).length) ||
    recordFormMode === FormMode.CREATE

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSelectLayer = (_layer: ILayer) => {
    goToLayer(_layer)
    dispatch(setRecords([]))
    dispatch(setActiveLayer(_layer))
    handleClose()
  }

  const onEditLayer = () => {
    dispatch(
      createLayerFormAction({
        isActive: true,
        action: LayerFormAction.UPDATE,
        step: 0,
      })
    )
  }

  const onSearchClick = () => {
    dispatch({
      reducer: 'flint.layout',
      type: 'secondaryDrawerOpened',
      payload: true,
    })
  }

  const onDeleteLayer = async () => {
    dispatch(deleteLayer(selectedLayer))
  }

  const loading = useMemo(() => {
    return loadingLayers
  }, [loadingLayers])

  const refetch = () => {
    const organizationId = activeOrg.id
    localStorage.setItem('layerId', selectedLayer.id.toString())
    dispatch(fetchLayers(organizationId))
  }

  const createReport = () => {
    toggleReportView(true)
  }

  return (
    <div className={clsx(classes.root, { [classes.hide]: !layers.length })}>
      <ReportView open={reportView} toggle={toggleReportView} />
      <Hide when={isActiveRecord}>
        {/* change current layer */}
        <div className={classes.textWrapper}>
          <LayersIcon />
          <Typography className={classes.layerText}>طبقة:</Typography>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.layersButton}
            onClick={handleClick}
            disabled={loading}
          >
            {selectedLayer?.title} <DownArrowIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {layers.map((__layer: ILayer) => (
              <MenuItem onClick={() => onSelectLayer(__layer)} key={__layer.id}>
                {__layer.title}
              </MenuItem>
            ))}
          </Menu>
        </div>
        {/* update layer and search in its records */}
        <div className={classes.buttonsWrapper}>
          {userCan('EDIT_LAYER') && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.editLayerButton}
              onClick={onEditLayer}
              disabled={loading}
              size="small"
            >
              تعديل الطبقة
            </Button>
          )}
          {userCan('DELETE_LAYER') && (
            <Button
              variant="contained"
              color="primary"
              className={classes.deleteLayerButton}
              size="small"
              onClick={onDeleteLayer}
              disabled={loading}
            >
              حذف الطبقة
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            className={classes.editLayerButton}
            onClick={createReport}
            size="small"
          >
            التقرير الاستكشافي
          </Button>
          <Tooltip title="تحديث البيانات">
            <IconButton
              color="default"
              className={classes.searchButton}
              size="medium"
              disabled={loading}
              onClick={refetch}
            >
              <RefreshIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="بحث داخل الطبقة">
            <IconButton
              color="default"
              className={classes.searchButton}
              size="medium"
              disabled={loading}
              onClick={onSearchClick}
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </Hide>
      <Hide when={!isActiveRecord}>
        <RecordActions />
      </Hide>
    </div>
  )
}

export default LeftComponent
