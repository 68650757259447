import { Button, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import useOrg from 'hooks/useOrg'
import React from 'react'
import { useDispatch } from 'react-redux'
import { createLayerFormAction } from 'store/layers/layer.actions'
import { LayerFormAction } from 'store/layers/layer.reducer'

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F6F6F6',
    borderRadius: 40,
    fontWeight: 'bold',
    padding: theme.spacing(1, 2),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    color: 'white',
    marginRight: theme.spacing(1),
  },
}))

function CreateLayerButton(props: React.HTMLAttributes<HTMLDivElement>) {
  const dispatch = useDispatch()
  const { hasPermissionTo } = useOrg()
  const classes = useStyle()
  // Open form modal
  const onClick = () => {
    dispatch(
      createLayerFormAction({
        isActive: true,
        step: 0,
        action: LayerFormAction.CREATE,
      })
    )
  }

  if (!hasPermissionTo('ADD_LAYER')) {
    return null
  }

  return (
    <div {...props}>
      <Button
        variant="contained"
        className={classes.root}
        disableElevation
        onClick={onClick}
      >
        <AddIcon className={classes.icon} />
        إنشاء طبقة جديدة
      </Button>
    </div>
  )
}

export default CreateLayerButton
