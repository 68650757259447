// @ts-nocheck
import {
  createStyles,
  TableCell,
  TableCellProps,
  TableRow,
  withStyles,
} from '@material-ui/core'

import { useMemo } from 'react'
import clsx from 'clsx'
import { getMatchedData, ISummarizedHeadProps } from 'utils/jsonSchema'

import { cloneDeep } from 'lodash'
import useStyle from './GeoTable.style'
import GeoTableBodyCell from './GeoTableBodyCell'

interface GeoTableRowProps {
  row: any
  headers: ISummarizedHeadProps[]
  onImageClicked: (url: string) => void
  onRowClick: (row: any) => void
  highlight?: boolean
}

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      '&:nth-of-type(odd)': {
        backgroundColor: '#f5f5f5',
      },
    },
  })
)(TableRow)

function CustomBodyCell(props: TableCellProps) {
  const classes = useStyle()
  return (
    <TableCell className={classes.customBodyCell} {...props}>
      {props.children && <strong>{props.children}</strong>}
    </TableCell>
  )
}

function GeoTableRow({
  row,
  headers,
  onImageClicked,
  onRowClick,
  highlight,
}: GeoTableRowProps) {
  const classes = useStyle()
  const data = useMemo((): ISummarizedHeadProps[] => {
    const __data = cloneDeep(headers)
    const __headerKeys = headers.map((h) => h.key)
    getMatchedData(__headerKeys, row.data, (key, value) => {
      const index = __data.findIndex((h) => h.key === key)
      __data[index].value = value
    })
    return __data
  }, [row, headers])

  return (
    <StyledTableRow
      style={{ cursor: 'pointer' }}
      className={clsx({ [classes.highlight]: highlight })}
    >
      {data.map((d) => (
        <CustomBodyCell key={d.key} onClick={() => onRowClick(row)}>
          <GeoTableBodyCell {...d} onImageClicked={onImageClicked} />
        </CustomBodyCell>
      ))}
    </StyledTableRow>
  )
}

export default GeoTableRow
