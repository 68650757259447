import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { Field, useField, useFormikContext } from 'formik'
import clsx from 'clsx'
import { isBrightenColor } from 'utils/colors'

const colors = [
  '#70b02e',
  '#000000',
  '#260081',
  '#B0FF3A',
  '#7700FF',
  '#005677',
  '#FF7010',
  '#DE1DA4',
  '#1DB732',
  '#AFE6FC',
  '#69F9EF',
  '#FFCC23',
  '#FF8214',
  '#1CC1FF',
  '#64580B',
  '#4E04FF',
  '#1DA3D6',
  '#334BF8',
  '#260083',
  '#BE0072',
  '#C92F2F',
]

const useStyle = makeStyles((theme) => ({
  colorsWrapper: {
    display: 'flex',
    listStyle: 'none',
    flexWrap: 'wrap',
    padding: 0,
  },
  colorItem: {
    minHeight: 50,
    width: 30,
    flexBasis: '10%',
    margin: 5,
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ontherColorButton: {
    position: 'relative',
    minHeight: 50,
    borderRadius: 10,
    fontWeight: 'bold',
  },
  colorInput: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    left: 0,
    opacity: 0,
  },
  activeColor: {
    boxShadow: theme.shadows[19],
  },
  hide: {
    display: 'none',
  },
}))

interface ColorsPaletteProps {
  name: string
}

function ColorsPalette({ name }: ColorsPaletteProps) {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(name)
  const { value } = field
  // get called when the color changes
  const changeColor = (color: string) => {
    setFieldValue(name, color)
  }
  const classes = useStyle()
  return (
    <div>
      <ul className={classes.colorsWrapper}>
        {colors.map((color, index) => (
          <li
            className={clsx(classes.colorItem, {
              [classes.activeColor]: field.value === color,
            })}
            key={index}
            onClick={() => changeColor(color)}
            style={{ backgroundColor: color }}
          >
            <CheckIcon
              fontSize="large"
              className={clsx({ [classes.hide]: color !== field.value })}
              style={{ color: isBrightenColor(color) ? 'black' : 'white' }}
            />
          </li>
        ))}
      </ul>
      <Button
        disableElevation
        variant="contained"
        color="primary"
        fullWidth
        className={clsx(classes.ontherColorButton)}
        size="large"
        style={{
          backgroundColor: value,
          color: isBrightenColor(value) ? 'black' : 'white',
        }}
      >
        <Field type="color" {...field} className={classes.colorInput} />
        إختيار لون اخر
      </Button>
    </div>
  )
}

export default ColorsPalette
