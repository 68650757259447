// @ts-nocheck
import React from 'react'
import { MapContainer, MapContainerProps } from 'react-leaflet'

import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'

import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet/dist/leaflet.css'

function GeoMap(props: MapContainerProps) {
  return (
    <MapContainer
      fullscreenControl
      center={[24.7255553, 46.5423431]}
      zoom={5}
      {...props}
    />
  )
}

export default GeoMap
