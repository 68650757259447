import React, { FC, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { StyleInjector } from 'components/StyleInjector'

interface HideProps {
  when: boolean | Array<boolean>
  element?: React.ReactNode | string
  children?: any
  clone?: boolean
}

const useStyle = makeStyles(() => ({
  hide: {
    display: 'none',
  },
}))

const Hide: FC<HideProps> = ({ element, when, children, clone }: HideProps) => {
  const classes = useStyle()
  const Component: any = useMemo(() => element, [element])
  const hide = useMemo(() => {
    if (Array.isArray(when)) {
      return when.some((e) => e)
    }
    return when
  }, [when])

  if (clone) {
    return (
      <StyleInjector classes={clsx({ [classes.hide]: hide })}>
        {children}
      </StyleInjector>
    )
  }

  return (
    <Component className={clsx({ [classes.hide]: hide })}>{children}</Component>
  )
}

Hide.defaultProps = {
  element: 'div',
}

export default Hide
