import { useFormikContext } from 'formik'
import { GFInput } from 'components/GFInput'
// import { GFSelect } from 'components/GFSelect'
import { useSelector } from 'react-redux'
import { LayerFormAction } from 'store/layers/layer.reducer'
import { RootState } from 'store/reducer'

// const layerTypes = [
//   { text: 'نقطة', value: 'POINT' },
//   { text: 'مضلع', value: 'POLYGON' },
//   { text: 'خط', value: 'LINESTRING' },
// ]

function LayerDetailsForm() {
  const { setFieldValue } = useFormikContext()
  const { layerForm } = useSelector((state: RootState) => state.layer)
  const isCreate = layerForm.action !== LayerFormAction.UPDATE

  const onChange = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    setFieldValue(name, value)
  }

  return (
    <div>
      {isCreate && (
        <GFInput
          onChange={onChange}
          placeholder="اكتب إسم الطبقة"
          label="إسم الطبقة"
          name="name"
        />
      )}
      <GFInput
        onChange={onChange}
        label="عنوان الطبقة"
        placeholder="اكتب الاسم"
        name="title"
      />

      <GFInput
        onChange={onChange}
        label="وصف الطبقه"
        placeholder="اكتب الوصف"
        name="description"
      />
    </div>
  )
}

export default LayerDetailsForm
