import { Alert } from '@material-ui/lab'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'

export interface LayerFormModalAlertsProps {
  fileHash: string
}

const URL = process.env.REACT_APP_API_BASE_URL

function LayerFormModalAlerts({ fileHash }: LayerFormModalAlertsProps) {
  const { layer } = useSelector((state: RootState) => state)
  const { serverError } = layer

  return (
    <Fragment>
      {serverError && (
        <Alert severity="error">{(serverError as any).message}</Alert>
      )}

      {fileHash && (
        <Alert severity="error" style={{ marginBottom: 10, marginTop: 10 }}>
          تحميل ملف بالسجلات التي لم يتم إدخالها
          <a
            download
            href={`${URL}/layers/download-errors/${fileHash}`}
            style={{ textDecoration: 'none' }}
          >
            <strong> تحميل الأن </strong>
          </a>
        </Alert>
      )}
    </Fragment>
  )
}

export default LayerFormModalAlerts
