import { CircularProgress, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'

interface LayerFormModalLoaderProps {
  loading: boolean
}

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 0,
    width: '100%',
    zIndex: theme.zIndex.modal,
    backgroundColor: '#eeec',
  },
  hide: {
    display: 'none',
  },
}))

function LayerFormModalLoader({ loading }: LayerFormModalLoaderProps) {
  const classes = useStyle()

  return (
    <div className={clsx(classes.root, { [classes.hide]: !loading })}>
      <CircularProgress />
      <Typography>برجاء الإنتظار</Typography>
    </div>
  )
}

export default LayerFormModalLoader
