import { Layout } from 'containers'

function HowToUse() {
  return (
    <Layout pageTitle="كيفية إستخدام تتبع" pageSubTitle="كيفية إستخدام تتبع">
      <h1>HowToUse</h1>
    </Layout>
  )
}

export default HowToUse
