import { graphQlQuery, graphQlMutate } from '@flint/graphql'
import { Dispatch } from 'react'
import { ILayer, FormMode, graphql, IRecord } from 'global'
import { toggleSnackbar } from 'store/layout'
import { GetStateFn } from 'store/reducer'
import layerService from 'services/layer.service'
import { cloneDeep } from 'lodash'
import {
  setLoadingMessage,
  createLayersLoaderAction,
  setLayers,
  setActiveLayer,
  setActiveRecord,
  changeRecordsView,
  setLoadingRecords,
  setRecords,
  createNewLayerLoader,
  setServerError,
  createLayerFormAction,
  toggleBoolState,
  setGeoTechLayers,
  setSearchMode,
  setLfLayer,
  setIsInfinitScroll,
  createLayerAction,
} from './layer.actions'

const {
  CREATE_RECORD,
  FETCH_RECORDS,
  CREATE_LAYER,
  DELETE_RECORD,
  UPDATE_LAYER,
  DELETE_LAYER,
} = graphql

const errorMessage = 'حدث خطاً ما'
/**
 * #################################################
 * Fetch layers for organization |||||||| ASYNC TASK
 * ##################################################
 */

export const fetchLayers = (
  orgId: number,
  onSuccess?: () => void,
  activeLayerId?: any,
  activeRecordId?: any,
  filters: any = {}
) => {
  return async (dispatch: Dispatch<any>) => {
    // enable loading
    dispatch(setLoadingMessage('جاري تحميل الطبقات'))
    dispatch(toggleBoolState('loadingLayers', true))

    Promise.all([
      layerService.fetch(orgId, filters),
      layerService.fetchRemote(orgId, {}),
    ])
      .then((responses: any) => {
        const [defaultResponse, gtResponse] = responses
        console.log('responses', responses)
        const layers = defaultResponse.layersList
        dispatch(setLayers(layers))
        let activeLayer = layers[0]
        if (activeLayerId) {
          const _layer = layers.find(
            (layer: ILayer) =>
              parseInt(layer.id as any, 10) === parseInt(activeLayerId, 10)
          )
          // if layer exits
          if (_layer) {
            activeLayer = _layer as ILayer
          }
        }

        const checkedLayers = []

        layers.forEach((l: ILayer) => {
          checkedLayers.push(l)
        })

        if (activeLayer) {
          dispatch(setActiveLayer(activeLayer))
        }

        // fetch geo tech layers
        const gtLayers = gtResponse.remoteLayersList.map((layer: ILayer) => ({
          ...layer,
          isGtLayer: true,
        }))
        gtLayers.forEach((l: ILayer) => {
          checkedLayers.push({
            id: l.id,
            isGtLayer: true,
          })
        })
        dispatch({
          reducer: 'layer',
          type: 'checkedLayers',
          payload: checkedLayers,
        })
        dispatch(setGeoTechLayers(gtLayers))
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((error: any) => {
        console.error(error)
      })
      .finally(() => {
        dispatch(setLoadingMessage(null))
        dispatch(toggleBoolState('loadingLayers', false))
      })
  }
}

// pass records to layer

/**
 * #################################################
 * UPDATE  RECORD ACTION
 * ##################################################
 */
export const updateRecord = (
  recordData: any,
  action?: 'NEXT' | 'PREV' | 'SUBMIT' | null
) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    dispatch(createLayersLoaderAction(true))
    dispatch(createLayerAction('updatedRecord', {}))
    try {
      const data = await layerService.updateRecord(recordData)
      const layerId = getState().layer.selectedLayer.id
      const layers = [...getState().layer.layers]
      const layerIndex = layers.findIndex(
        (layer: ILayer) => layer.id === layerId
      )
      const layer = layers[layerIndex]
      const records = [...layer.records]
      const bbRecords = [...(layer.bboxRecords || [])]
      const recordIndex = records.findIndex((r: IRecord) => r.id === data.id)
      const bboxRecordIndex = bbRecords.findIndex(
        (r: IRecord) => r.id === data.id
      )
      console.log('updated record', data)
      records[recordIndex] = data
      bbRecords[bboxRecordIndex] = data
      layer.records = records
      layer.bboxRecords = bbRecords
      layers[layerIndex] = layer
      dispatch(setLayers(layers))
      dispatch(setActiveLayer(layer))

      dispatch(createLayerAction('updatedRecord', data))

      if (action === 'NEXT') {
        dispatch(setActiveRecord(records[recordIndex + 1]))
      } else if (action === 'PREV') {
        // only if greater than 0
        if (recordIndex) {
          dispatch(setActiveRecord(records[recordIndex - 1]))
        }
      } else {
        dispatch(setActiveRecord({}))
        // set active record with the new data
        dispatch(changeRecordsView(FormMode.VIEW))
        // update, updated record
      }
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(createLayersLoaderAction(false))
    }
  }
}
/**
 * #################################################
 * CREATE NEW RECORD ACTION
 * ##################################################
 */

export const createRecord = (recordData: any) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    dispatch(createLayersLoaderAction(true))
    try {
      dispatch(setLoadingMessage('جاري إنشاء السجل'))
      const { data } = await graphQlMutate({
        mutation: CREATE_RECORD as any,
        variables: { recordData },
      })

      dispatch(setActiveRecord({}))
      // set active record with the new data
      dispatch(changeRecordsView(FormMode.VIEW))
      // layers with the new layer
      const record = data.createRecord.record as IRecord
      const selectedLayer = getState().layer.selectedLayer
      const layerId = selectedLayer.id
      // get copy of layers
      const layers = [...getState().layer.layers]
      // get layer index
      const index = layers.findIndex((l: ILayer) => l.id === layerId)
      const layer = layers[index]
      const records = (layer && layer.records) || []
      records.unshift(record)
      layer.records = records
      // check the new layer has bbox
      if (record.geometry) {
        // append layer to bbox records
        const bboxRecords = (layer && layer.bboxRecords) || []
        bboxRecords.unshift(record)
        layer.bboxRecords = bboxRecords
      }
      // udpate layer
      layers[index] = layer
      // update layers state
      setLayers(layers)
      // remove layer (leaflet layer) form the map if any
      const lfLayer = getState().layer.lfLayer
      const popup = getState().layer.openedPopup
      if (lfLayer) {
        lfLayer.closePopup()
        lfLayer.unbindPopup()
        lfLayer.remove()
        console.log('pppppppp', popup)
        popup.closePopup()
        popup.remove()
        dispatch(setLfLayer(undefined))
      }
      dispatch(
        toggleSnackbar({
          message: 'تم حفظ بيانات السجل الجديد بنجاح',
          open: true,
        })
      )
    } catch (error) {
      console.error(error)
      dispatch(toggleSnackbar({ message: errorMessage, open: true }))
    } finally {
      dispatch(setLoadingMessage(null))
      dispatch(createLayersLoaderAction(false))
    }
  }
}
/**
 * LIST RECORDS
 */
export const fetchRecords = (
  { id: layerId, title }: ILayer,
  variables: {},
  isSearchMode?: boolean,
  callback?: () => void
) => {
  return async (dispatch: Dispatch<any>, getState: any) => {
    try {
      dispatch(setLoadingMessage(`جاري تحميل السجلات الخاصة بطقة ${title}`))
      dispatch(setLoadingRecords(true))
      console.log('layerIdlayerId', layerId)
      const { data } = await graphQlQuery({
        query: FETCH_RECORDS as any,
        variables: {
          layerId,
          ...variables,
        },
      })

      // assing records to a layer
      const layers = [...getState().layer.layers]
      const layerIndex = layers.findIndex((l) => l.id === layerId)
      let _records = []
      _records = [...(data?.recordsList || [])]
      // if (isSearchMode) {
      //   _records = [...(data?.recordsList || [])]
      // } else {
      //   _records = [
      //     ...(layers[layerIndex].records || []),
      //     ...(data?.recordsList || []),
      //   ]
      // }

      layers[layerIndex].records = _records
      dispatch(setLayers(layers))
      dispatch(setRecords(_records))
      if (isSearchMode) {
        dispatch(setSearchMode(true))
      }
      if (callback) {
        callback()
      }
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setLoadingMessage(null))
      dispatch(setLoadingRecords(false))
      dispatch(setIsInfinitScroll(false))
    }
  }
}
/**
 * CREATE NEW LAYER ACTION
 */

export const createLayer = (layerData: any) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    dispatch(createNewLayerLoader(true))
    console.log('layerData', layerData)
    try {
      const { data } = await graphQlMutate({
        mutation: CREATE_LAYER as any,
        variables: { layerData },
      })
      dispatch(setServerError(null))
      dispatch(createLayerFormAction({ isActive: false, step: 0 }))
      const layer = data.createLayer.layer as ILayer
      layer.records = []
      const layers = [...getState().layer.layers] as any
      layers.unshift(layer)
      dispatch(setLayers(layers))
      dispatch(setActiveLayer(layer))
      dispatch(
        toggleSnackbar({
          message: 'تم حفظ بيانات الطبقة الجديدة بنجاح',
          open: true,
        })
      )
    } catch (error) {
      dispatch(setServerError(error))
    } finally {
      dispatch(createNewLayerLoader(false))
    }
  }
}

export const updateLayer = (layerData: any) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    dispatch(createNewLayerLoader(true))
    try {
      const { data } = await graphQlMutate({
        mutation: UPDATE_LAYER as any,
        variables: { layerData },
      })
      dispatch(createLayerFormAction({ isActive: false, step: 0 }))
      // update the selected layer

      const { layer } = data.updateLayer
      const layers = [...getState().layer.layers]
      const index = layers.findIndex((l) => l.id === layer.id)
      const oldLayer = layers[index]
      const updatedLayer = {
        ...layer,
        records: oldLayer.records,
        bboxRecords: oldLayer.bboxRecords,
      }
      layers[index] = updatedLayer
      dispatch(setLayers(layers))
      dispatch(setActiveLayer(updatedLayer))
      // update layer in all layers
    } catch (error) {
      dispatch(setServerError(error))
    } finally {
      dispatch(createNewLayerLoader(false))
    }
  }
}

export const fetchAliasRecords = (
  ttAlias: Array<{ id: number; name: string; alias: string }>,
  gtAlias: Array<{ id: number; name: string; alias: string }>,
  bbox: string,
  zoomLevel: number
) => {
  console.log('ALias', ttAlias, gtAlias)
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    dispatch(toggleBoolState('bboxLoadingRecords', true))
    // create query alias for records
    try {
      const [tRecrods, gtRecords] = await Promise.all([
        Object.keys(ttAlias).length
          ? layerService.fetchRecordsAlias(ttAlias, bbox)
          : {},
        Object.keys(gtAlias).length
          ? layerService.fetchGtRecords(gtAlias, bbox, zoomLevel)
          : {},
      ])
      console.log('==========fetchGtRecords==========', gtRecords)
      const layers: ILayer[] = [...getState().layer.layers]
      Object.keys(tRecrods)
        .filter((key: string) => {
          return key.includes('tatabaa_')
        })
        .forEach((key: string) => {
          // key is something like the following `tatabaa_38`
          const layerId: any = key.split('_')[1]
          const index = layers.findIndex(
            (layer: ILayer) => layer.id === layerId
          )
          layers[index].bboxRecords = tRecrods[key]
        })
      dispatch(setLayers(layers))
      // gt layers
      // geo tech layers
      const gtLayers: ILayer[] = [...getState().layer.geoTechLayers]
      Object.keys(gtRecords)
        .filter((key: string) => {
          return key.includes('gt_')
        })
        .forEach((key: string) => {
          const layerId: any = key.split('_')[1]
          const index = gtLayers.findIndex(
            (layer: ILayer) => layer.id === layerId
          )
          if (!gtRecords[key]) return
          console.log('gtRecords[key]', gtRecords[key])
          gtLayers[index].bboxRecords = gtRecords[key].records.features
          gtLayers[index].isGtLayer = true
          gtLayers[index].isClustered = gtRecords[key].clustered
        })
      console.log('gtLayers[index].bboxRecords', gtLayers)
      dispatch(setGeoTechLayers(gtLayers))
      console.log('fetchGtRecords', tRecrods, gtRecords)
      // console.log('gtLayers', gtLayers)
    } catch (error) {
      console.error(error)
      dispatch(toggleSnackbar({ message: errorMessage, open: true }))
    } finally {
      dispatch(toggleBoolState('bboxLoadingRecords', false))
    }

    // add records to each layer
    // set update layers with upadte layers
  }
}

export const deleteRecord = (recordId: number, type: any, layerId: number) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    try {
      const confirm = window.confirm('هل تريد حذف هذا السجل؟')
      if (!confirm) return
      dispatch(toggleBoolState('deletingRecord', true))
      dispatch(setLoadingMessage('جاري حذف السجل'))
      await graphQlMutate({
        mutation: DELETE_RECORD as any,
        variables: {
          recordInput: {
            id: recordId,
            type: type.toUpperCase(),
          },
        },
      })
      // update layers
      const layers: ILayer[] = cloneDeep(getState().layer.layers)
      const activeLayer = cloneDeep(getState().layer.selectedLayer)
      const layerIndex = layers.findIndex((l) => l.id === layerId)
      const bbRecordIndex = layers[layerIndex].bboxRecords?.findIndex(
        (r) => r.id === recordId
      )
      const recordIndex = layers[layerIndex].records?.findIndex(
        (r) => r.id === recordId
      )
      // delete records form layer
      if (recordIndex !== -1) {
        layers[layerIndex].records?.splice(recordIndex, 1)
        activeLayer.records?.splice(recordIndex, 1)
      }
      if (bbRecordIndex !== -1) {
        layers[layerIndex].bboxRecords?.splice(bbRecordIndex, 1)
        activeLayer.bboxRecords?.splice(bbRecordIndex, 1)
      }
      dispatch(setActiveLayer(activeLayer))
      // delete record form records
      const __recordIndex = getState().layer.records.findIndex(
        (r) => r.id === recordId
      )
      const nRecrods = [...getState().layer.records]
      dispatch(setActiveRecord(nRecrods[__recordIndex + 1]))
      nRecrods.splice(__recordIndex, 1)
      dispatch(setRecords(nRecrods))
      // update layers
      dispatch(setLayers(layers))
      dispatch(toggleSnackbar({ message: 'تم حذف السجل بنجاح', open: true }))
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setLoadingMessage(null))
      dispatch(toggleBoolState('deletingRecord', false))
    }
  }
}

export const deleteLayer = ({ id: layerId, title }: ILayer) => {
  return async (dispatch: Dispatch<any>, getState: GetStateFn) => {
    try {
      dispatch(toggleBoolState('deletingLayer', true))
      const confirm = window.confirm('هل تريد حذف هذه الطبقة ؟')
      if (!confirm) {
        return
      }
      dispatch(setLoadingMessage(`جاري حذف الطبقة ${title}`))
      await graphQlMutate({
        mutation: DELETE_LAYER as any,
        variables: { layerId },
      })
      // remove from layers
      const layers = [...getState().layer.layers]
      const index = layers.findIndex((l: ILayer) => l.id === layerId)
      layers.splice(index, 1)
      dispatch(toggleSnackbar({ message: 'تم حذف  الطبقة بنجاح', open: true }))
      dispatch(setLayers(layers))
      const activeLayer = layers[0] || {}
      dispatch(setActiveLayer(activeLayer as any))
    } catch (error) {
      dispatch(toggleSnackbar({ message: errorMessage, open: true }))
      console.error(error)
    } finally {
      dispatch(setLoadingMessage(null))
      dispatch(toggleBoolState('deletingLayer', false))
    }
  }
}
