import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    minWidth: 500,
    maxWidth: '60%',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    maxHeight: '90vh',
    overflowY: 'auto',
  },
}))

export default useStyle
