// /* global NodeJS */
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  AppProgress,
  // authenticateUserBack,
  // authenticateUser,
  useAuth,
} from '@flint/core'
import { Path } from 'global'
import { TatabaLogo } from 'icons'
import { DashboardPage } from 'pages'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { HowToUse } from 'pages/HowToUse'
import NoOrganization from 'components/NoOrganization'
import useOrg from 'hooks/useOrg'
import { fetchUserOrgs } from 'store/org'
import { RootState } from 'store'

// let protectedRoutesTimer: NodeJS.Timeout

function ProtectedRoutes() {
  // const location = useLocation()
  // show organization when it is loaded

  const dispatch = useDispatch()
  // use query for fetch user's organization
  const { organizations, loadingOrgs } = useOrg()
  // get authenticatoin status form flint
  const { loggedIn, authenticating } = useAuth()
  // fetch current layout config form flint
  const drawerToolbarState = useSelector(
    (state: RootState) => state.flint.layout.drawerToolbar
  )

  // Update app logo with the organization logo
  const updateOrgLogo = useCallback(() => {
    // update the current drawer toolbar state to obtain the organizaton logo
    const payload = { ...drawerToolbarState }
    payload.FullLogo = <TatabaLogo />

    dispatch({
      reducer: 'flint.layout',
      type: 'drawerToolbar',
      payload: drawerToolbarState,
    })
  }, [drawerToolbarState, dispatch])

  const hasNoOrg = useMemo(() => {
    if (loadingOrgs) return false
    if (!organizations || !organizations.length) return true
  }, [organizations, loadingOrgs])

  useEffect(() => {
    updateOrgLogo()
  }, [updateOrgLogo])

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchUserOrgs())
    }
  }, [loggedIn])

  // show laoding while authenticating
  if (authenticating || loadingOrgs || !loggedIn) return <AppProgress loading />

  if (hasNoOrg) return <NoOrganization />
  return (
    <Routes>
      <Route path="dashboard/*">
        <Route path="layer/:layerId/*">
          <Route path="record/:recordId" element={<DashboardPage />} />
          <Route path="" element={<DashboardPage />} />
        </Route>
        <Route path="" element={<DashboardPage />} />
      </Route>
      <Route path={Path.HOW_TO_USE} element={<HowToUse />} />
    </Routes>
  )
}

export default ProtectedRoutes
