// @ts-nocheck
import { Paper, Modal } from '@material-ui/core'
import { Formik } from 'formik'
import { useMemo, useState, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createLayerFormAction,
  createNewLayerLoader,
  setServerError,
} from 'store/layers/layer.actions'
import { RootState } from 'store/reducer'
import { arrPropsToObj } from 'utils/jsonSchema'
import { CreateLayerRequest } from 'global'
import { LayerFormAction } from 'store/layers/layer.reducer'
import { createLayer, updateLayer } from 'store/layers/layer.async'
import layerService from 'services/layer.service'
import { cloneDeep } from 'lodash'
import useOrg from 'hooks/useOrg'
import LayerFormModalLoader from './LayerFormModalLoader'
import useModalUtils from './hooks/useModalUtils'
import useStyle from './LayerFormModal.style'
import { FormHeader } from '../LayerCreationForm/FormHeader'
import LayerFormModalAlerts from './LayerFormModalAlerts'

interface LayerFormModalProps {
  steps: Array<{
    Component: any
    title: string
  }>
  initalValues: any
}

const LayerFormModal: FC<LayerFormModalProps> = ({
  steps,
  initalValues,
}: LayerFormModalProps) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { containerStyle, validationSchema } = useModalUtils()
  const { layer } = useSelector((state: RootState) => state)
  const { layerForm, newLayerLoader, selectedLayer } = layer
  const { activeOrg } = useOrg()
  const [fileHash, setFileHash] = useState<any>(null)
  const activeStep = useMemo(
    () => steps[layerForm.step],
    [layerForm.step, steps]
  )
  // change status of the modal
  const onClose = () => {
    dispatch(createLayerFormAction({ ...layerForm, isActive: false, step: 0 }))
    dispatch(setServerError(null))
  }
  const changeStep = (step: number) => {
    dispatch(createLayerFormAction({ ...layerForm, step }))
  }

  const publishRecords = async (values: any) => {
    // update layer json schema
    dispatch(createNewLayerLoader(true))
    try {
      const data = cloneDeep(values)
      const jsonSchemaObj = arrPropsToObj(data.jsonSchema)
      data.jsonSchema = jsonSchemaObj
      const jsonSchema = JSON.stringify(data.jsonSchema)
      const id = layerForm.fileUploadResponse.layer.id
      let columns

      await layerService.updateLayer(id, { jsonSchema })

      if (!layerForm.isGeoJson) {
        columns = {}
        if (layerForm.geoType === 'latlng') {
          columns.lonLatColumns = layerForm.lonLatColumns
        } else {
          columns.wktColumn = layerForm.wktColumn
        }
      }

      const { insertRecordsFromDataset } =
        await layerService.insertRecordsFromDataset(id, columns)
      if (insertRecordsFromDataset.publishStatus.errorsRecords) {
        setFileHash(insertRecordsFromDataset.publishStatus.errorsFileHash)
      } else {
        dispatch(createLayerFormAction({ isActive: false, step: 0 }))
      }
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(createNewLayerLoader(false))
    }
    // insert the field type in the database
  }

  const uploadDatasetFile = async (values: any) => {
    const data = { ...values }
    delete data.jsonSchema
    dispatch(createNewLayerLoader(true))

    try {
      const file = values.originalDataset
      const isTypeJson = file.type.toLocaleLowerCase().includes('json')
      const isGeoJson = isTypeJson || /\.(json|geojson)/.test(file.name)
      data.organization = activeOrg.id
      const { createLayerFromDataset: response } =
        await layerService.createLayerFromDataset(data)
      console.log('uploadDatasetFile', response)

      dispatch(
        createLayerFormAction({
          ...layerForm,
          step: layerForm.step + 1,
          isFileUploaded: true,
          isGeoJson,
          fileUploadResponse: response,
        })
      )
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(createNewLayerLoader(false))
    }
  }
  // on submit form data
  const onSubmit = (values: CreateLayerRequest) => {
    // on submit
    /// form data set
    const isFromDataset = layerForm.step === 2 && layerForm.source === 'dataset'

    if (isFromDataset) {
      uploadDatasetFile(values)
      return
    }
    if (layerForm.step + 1 < steps.length) {
      dispatch(
        createLayerFormAction({ ...layerForm, step: layerForm.step + 1 })
      )
      return
    }
    // savign
    if (layerForm.source === 'dataset') {
      publishRecords(values)
      return
    }

    // create new
    const layerData: any = cloneDeep(values)
    if (layerForm.action !== LayerFormAction.UPDATE) {
      const jsonSchema = arrPropsToObj(layerData.jsonSchema)

      layerData.jsonSchema = jsonSchema
      layerData.jsonSchema = JSON.stringify(layerData.jsonSchema)

      layerData.organization = activeOrg.id
      dispatch(createLayer(layerData))
      return
    }
    layerData.id = selectedLayer.id
    dispatch(updateLayer(layerData))
  }

  return (
    <Modal className={classes.root} open={layerForm.isActive}>
      <div className={classes.container} style={containerStyle}>
        <LayerFormModalLoader loading={newLayerLoader} />
        <Paper className={classes.paper}>
          <FormHeader
            onChangeStep={changeStep}
            totalSteps={steps.length}
            activeStepNumber={layerForm.step}
            title={activeStep.title}
            onClose={onClose}
          />

          <LayerFormModalAlerts fileHash={fileHash} />
          <Formik
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={initalValues}
          >
            {() => <activeStep.Component />}
          </Formik>
        </Paper>
      </div>
    </Modal>
  )
}

export default LayerFormModal
