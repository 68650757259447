import React from 'react'
import { HomePage } from 'pages'
import { Routes, Route } from 'react-router-dom'
import ProtectedRoutes from './ProtectedRoutes'

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<ProtectedRoutes />} />
    </Routes>
  )
}

export default AppRoutes
