import {
  createStyles,
  fade,
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  Theme,
  withStyles,
} from '@material-ui/core'
import { Field, useField } from 'formik'

const GtTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '16px 12px',
      lineHeight: '25px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: ['Almarai, sans-serif'].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.١rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  })
)(InputBase)

export function GFInput({ id, label, type, styles, ...props }: any) {
  const [, meta] = useField(props)
  const { error, touched } = meta
  const errorText = touched && error
  return (
    <FormControl style={{ width: '100%' }} hiddenLabel={type === 'hidden'}>
      <InputLabel
        htmlFor={id}
        shrink
        style={{
          fontSize: 15,
          fontWeight: 'bold',
          color: '#16151a',
        }}
      >
        {label}
      </InputLabel>
      <Field
        as={GtTextField}
        id={id}
        style={{ ...styles }}
        error
        type={type}
        {...props}
      />
      <FormHelperText error={!!errorText}>{errorText || null}</FormHelperText>
    </FormControl>
  )
}
