import React from 'react'
import { makeStyles, Typography, CircularProgress } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import clsx from 'clsx'
import { CreateLayerButton } from 'components/CreateLayerButton'

const useStyle = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    minHeight: 300,
    border: '1px solid #ccc',
    borderRadius: 10,
  },
  warningIcon: {
    color: '#616060',
    fontSize: theme.spacing(8),
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    margin: theme.spacing(0.5, 0),
  },
  message: {
    margin: theme.spacing(0.5, 0),
    fontSize: 15,
  },
  hide: {
    display: 'none',
  },
}))

interface NoResultProps {
  status?: 'EMPTY' | 'LOADING'
  open?: boolean
  message?: string
  title?: string
  button?: any
}

const texts = {
  EMPTY: {
    title: 'لم يتم إنشاء آي طبقة',
    message: 'يرجى إنشاء طبقة جديدة للبدا باستخدام تتبع',
  },
  LOADING: {
    title: 'برجاء الإنتظار',
    message: 'جاري تحميل البيانات المطلوبة',
  },
}

function NoResult({
  open = true,
  status = 'EMPTY',
  message,
  title,
  button,
}: NoResultProps) {
  const classes = useStyle()
  const isLoading = status === 'LOADING'

  return (
    <div className={clsx(classes.root, { [classes.hide]: !open })}>
      <CircularProgress className={clsx({ [classes.hide]: !isLoading })} />
      <WarningIcon
        className={clsx(classes.warningIcon, { [classes.hide]: isLoading })}
        fontSize="large"
      />
      <Typography className={classes.title}>
        {title || texts[status].title}
      </Typography>
      <Typography className={classes.message}>
        {message || texts[status].message}
      </Typography>
      {button || (
        <CreateLayerButton className={clsx({ [classes.hide]: isLoading })} />
      )}
    </div>
  )
}

export default NoResult
