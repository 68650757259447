import { CreateLayerRequest, SchemaBuilderViews } from 'global'
import { useFormikContext } from 'formik'

import { useMemo } from 'react'
import { arrPropsToObj, objPropsToArr } from 'utils/jsonSchema'
import Editor from './Editor'

interface JsonSchemaEditorProp {
  editorView: SchemaBuilderViews
}

let editorViewUpdateTimer

function EditorView({ editorView }: JsonSchemaEditorProp) {
  const { values, setFieldValue } = useFormikContext<CreateLayerRequest>()

  const name = useMemo(() => {
    switch (editorView) {
      case SchemaBuilderViews.SCHEMA:
        return 'jsonSchema'
      case SchemaBuilderViews.UI_WEB_SCHEMA:
        return 'webUiJsonSchema'
      case SchemaBuilderViews.UI_MOBILE_SCHEMA:
        return 'mobileUiJsonSchema'
      default:
        return null
    }
  }, [editorView])

  const isJsonSchema = useMemo(
    () => editorView === SchemaBuilderViews.SCHEMA,
    [editorView]
  )

  const updateJsonSchema = (value: string) => {
    try {
      let schema = JSON.parse(value)
      schema = objPropsToArr(schema)
      setFieldValue(name, schema)
    } catch (error) {
      console.error(error)
    }
  }

  const onChange = (value: string, name: string) => {
    try {
      if (isJsonSchema) {
        if (editorViewUpdateTimer) clearTimeout(editorViewUpdateTimer)
        editorViewUpdateTimer = setTimeout(() => {
          updateJsonSchema(value)
        }, 1000)
      } else {
        try {
          setFieldValue(name, JSON.stringify(JSON.parse(value), null, 4))
        } catch (error) {
          console.error(error)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  const stringifiedCode = useMemo(() => {
    const value = values[name]
    if (typeof value === 'string') return value
    if (isJsonSchema) {
      const schema = arrPropsToObj(value)
      return JSON.stringify(schema, null, 4)
    }
  }, [name, values, isJsonSchema])

  return (
    <div style={{ direction: 'ltr' }}>
      <Editor value={stringifiedCode} onChange={onChange} name={name} />
    </div>
  )
}

export default EditorView
