import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Theme,
} from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useCallback, useEffect } from 'react'

const useStyle = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  warnIcon: {
    fontSize: 50,
    color: theme.palette.warning.main,
  },
}))

interface ConfirmDialogProps {
  isOpen: boolean
  ignoreFn?: () => void
  submitFn?: () => void
}

function ConfirmDialog({ isOpen, ignoreFn, submitFn }: ConfirmDialogProps) {
  const classes = useStyle()

  const ignoreHandler = () => {
    if (ignoreFn) {
      ignoreFn()
    }
  }

  const onSubmit = useCallback(() => {
    if (submitFn) {
      submitFn()
    }
  }, [submitFn])

  const onKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      const keyCode = ev.keyCode
      const key = ev.key
      if (keyCode === 13 && isOpen) {
        ev.preventDefault()
        onSubmit()
      }
      console.log('ConfirmDialog [KeyCode] [key]', keyCode, key)
    },
    [onSubmit, isOpen]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    console.log('ConfirmDialog mounted')
    return () => {
      console.log('ConfirmDialog unmounted')
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return (
    <Dialog
      open={isOpen}
      onEscapeKeyDown={ignoreHandler}
      maxWidth="sm"
      fullWidth
      style={{ textAlign: 'center', padding: 60 }}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        <Warning className={classes.warnIcon} />
        <Typography variant="h5" color="error">
          تحذير!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5">لم تقم بحفظ التغيرات</Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          color="secondary"
          variant="contained"
          disableElevation
          onClick={ignoreHandler}
        >
          إلغاء
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={onSubmit}
        >
          حفظ و إستكمال
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
