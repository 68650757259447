import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  highlight: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: '#f5f5f5',
  },
  cell: {
    backgroundColor: '#747474',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Almarai',
  },
  cellIcon: {
    backgroundColor: '#B8B8B8',
    borderRadius: 10,
    marginLeft: 10,
  },
  customBodyCell: {
    fontFamily: 'Almarai',
  },
  tableContainer: {
    border: '1px solid #eee',
    borderRadius: 10,
    boxShadow: 'none',
  },
  filterCount: {
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#F5E8EA',
    borderRadius: 50,
    padding: theme.spacing(1, 5),
    cursor: 'default',
  },
}))
export default useStyle
