/* global GeoJSON */
import { ILayer, IRecord, FormMode, DefaultOptions } from 'global'
import { Layer, Popup } from 'leaflet'
import { Dispatch } from 'react'
import { GetStateFn } from 'store/reducer'
import { fetchRecords } from './layer.async'

import { ILayerForm, ILayerState } from './layer.reducer'

/**
 * #################################################################
 *                    ACTIONS BUILDER
 * #################################################################
 */
const reducer = 'layer'

export const createLayerAction = (type: keyof ILayerState, payload: any) => ({
  reducer,
  type,
  payload,
})
// for setting all layers
export const setLayers = (payload: ILayer[]) => {
  return { reducer, type: 'layers', payload }
}

// SETTING RECORDS FOR LAYERS
export const setRecords = (payload: Record<number, IRecord>) => ({
  reducer,
  type: 'records',
  payload,
})

// SEARDCH MODE
export const setSearchMode = (payload: boolean) => ({
  reducer,
  type: 'isSearchMode',
  payload,
})

export const setActiveLayer = (payload: ILayer, callback?: () => void) => {
  localStorage.setItem('layerId', payload.id as any)
  // check if layer's records
  // the records field is undefined, records have not loaded yet.
  // if records field is an object, it has loaded before and should fetch records again
  return (dispatch: Dispatch<any>, storeFn: GetStateFn) => {
    if (typeof payload.records === 'undefined') {
      // fetch records
      if (payload && Object.keys(payload).length) {
        const limit = DefaultOptions.RECORD_LIMIT
        const recordsPage = storeFn().layer.recordsPage
        const skip = (recordsPage - 1) * limit
        console.log('skiiip', skip)
        console.log('skiiip', recordsPage)
        dispatch(fetchRecords(payload, { pageInfo: { offset: skip, limit } }))
      }
    } else {
      dispatch(setRecords(payload.records))
      if (callback) {
        callback()
      }
    }
    dispatch(setSearchMode(false))

    dispatch({
      reducer,
      type: 'selectedLayer',
      payload,
    })
  }
}

// set geo tech layers
export const setGeoTechLayers = (payload: ILayer[]) => ({
  reducer,
  type: 'geoTechLayers',
  payload,
})
// FOR POINT SELECTED ON MAP
export const createSelectPointAction = (payload: string) => ({
  reducer,
  type: 'selectedPoint',
  payload,
})
// FOR setGeoJson
export const setGeoJson = (payload: GeoJSON.Geometry) => ({
  reducer,
  type: 'selectedGeoJson',
  payload,
})
// FOR THE CURRENT ACTIVE RECORD
export const setActiveRecord = (payload: IRecord | {}) => ({
  reducer,
  type: 'selectdRecord',
  payload,
})
// WHEATEHR OR NOT THE INPUTS ARE READONLY
export const changeRecordsView = (payload: FormMode) => ({
  reducer,
  type: 'recordFormMode',
  payload,
})
// FIRE THE STATUS OF LOADING LAYERS
export const createLayersLoaderAction = (payload: boolean) => ({
  reducer,
  type: 'loadingLayers',
  payload,
})
// setLoadingMessage
export const setLoadingMessage = (payload: string) => ({
  reducer,
  type: 'loadingMessage',
  payload,
})
// setLoadingRecords
export const setLoadingRecords = (payload: boolean) => ({
  reducer,
  type: 'loadingRecords',
  payload,
})
export const setIsInfinitScroll = (payload: boolean) => ({
  reducer,
  type: 'isInfinitScroll',
  payload,
})
// FIRE THE STATUS OF LOADING LAYERS
export const createLayerFormAction =
  (payload: ILayerForm) => (dispatch: any, getState: GetStateFn) => {
    dispatch({
      reducer,
      type: 'layerForm',
      payload: { ...getState().layer.layerForm, ...payload },
    })
  }
// FIRE THE STATUS OF LOADING LAYERS
export const createNewLayerLoader = (payload: boolean) => ({
  reducer,
  type: 'newLayerLoader',
  payload,
})
// FIRE CHANGE LOCATION MODE
export const toggleChangeLocationMode = (payload: boolean) => ({
  reducer,
  type: 'changeLocationMode',
  payload,
})

// SETTING RECORDS FOR LAYERS
export const setServerError = (payload: any) => ({
  reducer,
  type: 'serverError',
  payload,
})

export const setLocationForUpdate = (payload: any) => ({
  reducer,
  type: 'locationForUpdate',
  payload,
})

export const setLfLayer = (payload: Layer) => ({
  reducer,
  type: 'lfLayer',
  payload,
})

export const setOpenedPopup = (payload: Popup) => ({
  reducer,
  type: 'openedPopup',
  payload,
})

type BoolKeys =
  | 'bboxLoadingRecords'
  | 'deletingRecord'
  | 'deletingLayer'
  | 'loadingRecords'
  | 'loadingLayers'
  | 'layersUpdated'

export const toggleBoolState = (key: BoolKeys, payload: boolean) => {
  return {
    reducer: 'layer',
    type: key,
    payload,
  }
}

export const addLayerControl = (layer: ILayer) => {
  return (dispatch: Dispatch<any>, getState: GetStateFn) => {
    const checkedLayers = [...getState().layer.checkedLayers]
    checkedLayers.push(layer)
    dispatch({
      reducer,
      type: 'checkedLayers',
      payload: checkedLayers,
    })
  }
}
export const removeLayerControl = (layerId: number, isGtLayer: boolean) => {
  return (dispatch: Dispatch<any>, getState: GetStateFn) => {
    const checkedLayers = [...getState().layer.checkedLayers]
    const index = checkedLayers.findIndex(
      // eslint-disable-next-line eqeqeq
      (lyr: ILayer) => lyr.id === layerId && lyr.isGtLayer == isGtLayer
    )
    checkedLayers.splice(index, 1)
    dispatch({
      reducer,
      type: 'checkedLayers',
      payload: checkedLayers,
    })
  }
}

export const setViewMode = () => {
  return (dispatch: any) => {
    dispatch(setActiveRecord({}))
    dispatch(changeRecordsView(FormMode.VIEW))
    dispatch(setLocationForUpdate(null))
    dispatch(toggleChangeLocationMode(false))
  }
}
