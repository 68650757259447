import { ILayer } from 'global'

import { LayersControl, LayerGroup } from 'react-leaflet'

import { memo } from 'react'

interface SingleLayerControlProps {
  layer: ILayer
}

function SingleLayerControl({ layer }: SingleLayerControlProps) {
  return (
    <LayersControl.Overlay name={layer.title} key={layer.id} checked>
      <div
        style={{ display: 'none' }}
        className="layer_control_details_storage"
      />
      <LayerGroup />
    </LayersControl.Overlay>
  )
}

export default memo(SingleLayerControl)
