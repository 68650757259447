import { Dialog, Paper } from '@material-ui/core'

interface LargeImageViewProps {
  largeImageView: string
  setLargeImageView: (src: any) => void
}

function LargeImageView({
  largeImageView,
  setLargeImageView,
}: LargeImageViewProps) {
  return (
    <Dialog
      maxWidth="lg"
      open={!!largeImageView}
      onClose={() => setLargeImageView(null)}
    >
      <Paper style={{ padding: 10 }}>
        <img
          src={largeImageView}
          alt="any"
          style={{ margin: 'auto', maxWidth: '100%' }}
        />
      </Paper>
    </Dialog>
  )
}

export default LargeImageView
