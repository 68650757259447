import { SchemaBuilderViews } from 'global'
import { useState } from 'react'
import BuilderViews from '..'
import EditorView from '../EditorView'

const layerSchemaTabs = [
  {
    tab: SchemaBuilderViews.SCHEMA,
    label: 'json schema',
    Component: EditorView,
  },
  {
    tab: SchemaBuilderViews.UI_WEB_SCHEMA,
    label: 'web ui schema',
    Component: EditorView,
  },
  {
    tab: SchemaBuilderViews.UI_MOBILE_SCHEMA,
    label: 'mobile ui schema',
    Component: EditorView,
  },
]

function CodeEditor() {
  const [activeTab, setTab] = useState<SchemaBuilderViews>(
    SchemaBuilderViews.SCHEMA
  )
  return (
    <BuilderViews
      tabs={layerSchemaTabs}
      onTabChange={setTab}
      activeTab={activeTab}
    />
  )
}

export default CodeEditor
