import { LatLngBounds } from 'leaflet'

export const stringifyBounds = (bounds: any) => {
  const polygon = `POLYGON (( ${bounds.getNorthEast().lng}   ${
    bounds.getNorthEast().lat
  },${bounds.getNorthEast().lng}   ${bounds.getNorthEast().lat},${
    bounds.getSouthWest().lng
  } ${bounds.getNorthEast().lat},${bounds.getSouthWest().lng}${
    bounds.getNorthEast().lat
  },${bounds.getNorthEast().lng}   ${bounds.getNorthEast().lat} ))`
  return polygon
}

export const genericBoundsToNwSe = (bounds: any) => {
  const SouthEast: any = [bounds.getNorthEast().lng, bounds.getSouthWest().lat]
  const NorthWest: any = [bounds.getSouthWest().lng, bounds.getNorthEast().lat]
  return {
    nw: {
      lat: NorthWest[1],
      lng: NorthWest[0],
    },
    se: {
      lat: SouthEast[1],
      lng: SouthEast[0],
    },
  }
}

export const simplifyCoord = (coords: any) => {
  if (Array.isArray(coords[0])) {
    if (Array.isArray(coords[0][0])) {
      return `${coords[0][0].join(',')}....`
    }
    return `${coords[0].join(',')}....`
  }
  return coords.join(',')
}

export const boundsToString = (bounds: LatLngBounds): string => {
  return `[${bounds.toBBoxString()}]`
}
