import { useTheme } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { NewLayerFrom } from '../LayerDetails'

import { LayerFormModal } from '../LayerFormModal'
import { LayerSchema } from '../LayerSchema'
import { NewLayerSource } from '../NewLayerSource'
import { UploadDataset } from '../UploadDataset'

const insertAt = (array: any[], index: number, ...elements: any) => {
  array.splice(index, 0, ...elements)
}

function LayerCreationForm() {
  const theme = useTheme()
  const { layerForm } = useSelector((state: RootState) => state.layer)
  // An array of the steps of the form creation
  const [steps, setSteps] = useState([
    // step for select whether it is a layer or an existatnce one
    { title: 'اختر آلية إنشاء الطبقة', Component: NewLayerSource },
    // step for adding layer details
    { title: 'إضافة طبقة جديدة', Component: NewLayerFrom },

    // step for creating internal layer schema/specifications
    { title: 'انشاء النموذج الخاص بالطبقة', Component: LayerSchema },
  ])

  const initialValues = {
    name: '',
    title: '',
    description: '',
    geometryColor: theme.palette.primary.main,
    originalDataset: undefined,
    mobileUiJsonSchema: JSON.stringify({}),
    webUiJsonSchema: JSON.stringify({}),
    jsonSchema: {
      type: 'object',
      $schema: 'http://json-schema.org/schema',
      properties: [
        {
          title: '',
          propKey: '',
          type: 'string',
          isSummaryField: false,
        },
      ],
      required: [],
    },
  }

  useEffect(() => {
    if (layerForm.source === 'dataset' && steps.length === 3) {
      const __steps = [...steps]
      // append the file upload steps in case of data set type
      insertAt(__steps, 2, {
        title: 'ارفاق ملف الاكسيل',
        Component: UploadDataset,
      })
      setSteps(__steps)
    } else if (layerForm.source === 'regular' && steps.length === 4) {
      // remove upload component/step in case it is inserted and type switched
      const __steps = [...steps]
      __steps.splice(2, 1)
      setSteps(__steps)
    }
  }, [layerForm.source, steps])

  return <LayerFormModal initalValues={initialValues} steps={steps} />
}

export default LayerCreationForm
