import { Skeleton } from '@material-ui/lab'
import { Suspense, useMemo } from 'react'
import { isObject } from '@flint/utils'
import { ISummarizedHeadProps } from 'utils/jsonSchema'
import SuspenseImage from 'utils/resources/SuspenseImage'

interface GeoTableBodyCellProps extends ISummarizedHeadProps {
  onImageClicked: (url: string) => void
}

function GeoTableBodyCell({
  isImage,
  value,
  onImageClicked,
}: GeoTableBodyCellProps) {
  const imgSrc = useMemo(() => {
    return Array.isArray(value) ? value[0] : value
  }, [value])

  // if an image
  if (isImage) {
    return (
      <Suspense fallback={<Skeleton variant="rect" width={101} height={102} />}>
        <SuspenseImage
          onClick={(event: any) => {
            event.stopPropagation()
            if (onImageClicked) {
              onImageClicked(imgSrc)
            }
          }}
          src={imgSrc}
          width={100}
          style={{ padding: 2, background: '#747474' }}
        />
      </Suspense>
    )
  }

  return <span>{!isObject(value) && value}</span>
}

export default GeoTableBodyCell
