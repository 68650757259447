// @ts-nocheck
import { IRecord } from 'global'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core'
import { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveRecord } from 'store/layers'
import { RootState } from 'store/reducer'
import { ISummarizedHeadProps } from 'utils/jsonSchema'
import { goToLayer } from 'utils/common'
import useStyle from './GeoTable.style'
import GeoTableRow from './GeoTableRow'
import LargeImageView from './LargeImageView'

function CustomCell({ text }: { text: string }) {
  const classes = useStyle()
  return <TableCell className={classes.cell}>{text}</TableCell>
}

interface GeoTableProps {
  headers?: ISummarizedHeadProps[]
  rows: Array<{ data: any; id: number }>
}

function GeoTable({ headers = [], rows = [] }: GeoTableProps) {
  const classes = useStyle()
  const { isSearchMode, highlightRecord, selectedLayer } = useSelector(
    (state: RootState) => state.layer
  )
  const [largeImageView, setLargeImageView] = useState<any>()
  const dispatch = useDispatch()

  const onRecordClick = (record: IRecord) => {
    const recordPath = `/record/${record.id}`

    goToLayer(selectedLayer, recordPath)
    dispatch(setActiveRecord(record))
  }

  return (
    <Fragment>
      <LargeImageView
        largeImageView={largeImageView}
        setLargeImageView={setLargeImageView}
      />
      {/* <Typography style={{ marginBottom: 10, marginTop: 10 }}>
        عدد السجلات في الطبقة: <strong>{rows.length}</strong>
      </Typography> */}
      <TableContainer className={classes.tableContainer}>
        <Box
          textAlign="center"
          my={2}
          display={isSearchMode ? 'block' : 'none'}
        >
          <Button className={classes.filterCount}>
            تم أيجاد {rows && rows.length} نتائج
          </Button>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(({ title }, index) => (
                <CustomCell key={index} text={title} />
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {(rows || []).map((row: any, index: number) => (
              <GeoTableRow
                highlight={highlightRecord === row.id}
                onRowClick={onRecordClick}
                row={row}
                headers={headers}
                key={index}
                onImageClicked={setLargeImageView}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  )
}

export default GeoTable
