import validationSchemas from 'containers/DashboardContainer/LayerCreationForm/validationSchema'
import { useMemo, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'

const useModalUtils = () => {
  const {
    layer: { layerForm, isAdvancedSchema },
  } = useSelector((state: RootState) => state)
  const containerStyle = useMemo(() => {
    const style: CSSProperties = {}
    if (
      (layerForm.step === 2 && layerForm.source === 'regular') ||
      (layerForm.step === 3 && layerForm.source === 'dataset')
    ) {
      style.width = '90%'
      style.maxWidth = '90%'
    }
    return style
  }, [layerForm.step, layerForm.source])

  // validation
  const validationSchema = useMemo(() => {
    if (
      (layerForm.step === 2 && layerForm.source === 'dataset') ||
      isAdvancedSchema
    ) {
      return null
    }
    return validationSchemas[layerForm.step - 1]
  }, [layerForm.step, layerForm.source, isAdvancedSchema])

  return {
    containerStyle,
    validationSchema,
  }
}

export default useModalUtils
