import { Alert } from '@material-ui/lab'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import LayerSchemaCoordinates from './LayerSchemaCoordinates'

interface LayerSchemaHeaderProps {
  errorMessage: string | Array<string>
}

function LayerSchemaHeader({ errorMessage }: LayerSchemaHeaderProps) {
  const { layerForm } = useSelector((state: RootState) => state.layer)

  return (
    <Fragment>
      {layerForm.isFileUploaded && !layerForm.isGeoJson && (
        <LayerSchemaCoordinates />
      )}
      <Alert
        style={{
          marginBottom: 20,
          display: errorMessage ? 'flex' : 'none',
          alignItems: 'center',
        }}
        severity="error"
      >
        {Array.isArray(errorMessage) ? (
          <ul style={{ margin: 0, padding: 0, listStyle: 'inside' }}>
            {errorMessage.map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        ) : (
          errorMessage
        )}
      </Alert>
    </Fragment>
  )
}

export default LayerSchemaHeader
