import { Button, Grid, Link } from '@material-ui/core'
import React from 'react'
import { Layout, useAuth } from '@flint/core'
import { useTranslation } from '@flint/locales'
import { useStyle } from './Home.style'
import {
  AppleStoreIcon,
  GoogleStoreIcon,
  NavigationPanaIcon,
  TatabaLogo,
} from '../../icons'

function HomePage() {
  const { loggedIn } = useAuth()
  const classes = useStyle()
  const { t } = useTranslation()

  const authenticate = () => {
    location.href = '/dashboard'
  }

  return (
    <div className={classes.root}>
      <Layout
        // className={classes.root}
        disableDrawer
        disableToolbar
      >
        <div className={classes.container}>
          <Grid container className={classes.headerContainer}>
            <Grid item xs={12} md={6} className={classes.platformDescription}>
              <div className={classes.platformContent}>
                <TatabaLogo className={classes.logo} />

                <h1 className={classes.headerTitle}>البوابة المكانية</h1>
              </div>
            </Grid>

            <Grid item xs={12} md={6} className={classes.headerBannerContainer}>
              <NavigationPanaIcon className={classes.headerBanner} />
            </Grid>
          </Grid>

          <Grid container className={classes.footerContainer}>
            <Grid item xs={12} md={6} className={classes.loginContainer}>
              <div className={classes.loginContent}>
                <div className={classes.loginTitle}>
                  {t('login to the platfrom')}
                </div>

                <div className={classes.actionsContainer}>
                  <Button
                    variant="contained"
                    aria-label="login"
                    disableElevation
                    onClick={authenticate}
                    className={classes.loginButton}
                    disabled={loggedIn}
                  >
                    {loggedIn ? 'Checking your account' : t('login')}
                  </Button>

                  <Button
                    variant="contained"
                    aria-label="register"
                    disableElevation
                    onClick={authenticate}
                    className={classes.newRegisterButton}
                    disabled={loggedIn}
                  >
                    {t('new registration')}
                  </Button>
                </div>

                <Link href="/privacy" className={classes.termsOfUse}>
                  {t('terms of use and privacy policy')}
                </Link>
              </div>
            </Grid>

            <Grid item xs={12} md={6} className={classes.appLinkContainer}>
              <div>
                <div className={classes.downloadTitle}>{t('download app')}</div>

                <div className={classes.storesContainer}>
                  <Link
                    aria-label="Apple store download"
                    className={classes.storeLink}
                  >
                    <AppleStoreIcon className={classes.appleStoreLogo} />
                  </Link>

                  <Link
                    aria-label="Google store download"
                    className={classes.storeLink}
                  >
                    <GoogleStoreIcon className={classes.googleStoreLogo} />
                  </Link>
                </div>

                <div className={classes.copyright}>{t('copyright')}</div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Layout>
    </div>
  )
}

export default HomePage
