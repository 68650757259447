// @ts-nocheck
/* global NodeJS */

import {
  createTileLayerComponent,
  updateGridLayer,
  withPane,
  LeafletContextInterface,
  LayerProps,
} from '@react-leaflet/core'

import L from 'leaflet'
import './leafletWMSHeader'
import 'Leaflet-WMS'
import _ from 'lodash'
import { memo } from 'react'

let _updateTileTimer: NodeJS.Timer

interface GeoTechWmsProps extends LayerProps {
  headers: Record<string, any>
  url: string
  params: L.WMSParams
}

let geoTechWmsTimer

export const GeoTechWms = memo(
  createTileLayerComponent(
    function createWMSTileLayer(
      {
        params: passedParams = {} as any,
        layerCreated,
        url,
        headers = {},
        onFinish,
        onFail,
        onSuccess,
        layerGroup,
        abort,
        ...options
      }: GeoTechWmsProps,
      context: LeafletContextInterface
    ) {
      console.log('createWMSTileLayer')

      const params = {
        ...passedParams,
        tileSize: context.map.getSize(),
      }
      const _headers = Object.keys(headers).map((key) => ({
        header: key,
        value: headers[key],
      }))
      const _wmsInstance = L.TileLayer.wmsHeader(
        url,
        {
          ...params,
          ...withPane(options, context),
        },
        _headers,
        abort,
        null
      )

      if (geoTechWmsTimer) {
        clearTimeout(geoTechWmsTimer)
      }

      geoTechWmsTimer = setTimeout(() => {
        if (layerCreated) {
          layerCreated(layerGroup, _wmsInstance)
        }
      }, 1000)

      const onMapClick = (e: any) => {
        // console.log('instancesss', _wmsInstance.getFeatureInfo)
        _wmsInstance.getFeatureInfo({
          latlng: { lat: e.latlng.lat, lng: e.latlng.lng },
          featureCount: 50,
          headers,
          params,
          uppercase: true,
          infoFormat: 'application/json',
          done(featureCollection: any) {
            if (onSuccess) {
              onSuccess(featureCollection, e, layerGroup)
            }
          },
          fail(errorThrown: any) {
            if (onFail) {
              onFail(errorThrown)
            }
          },
          always() {
            if (onFinish) {
              onFinish(e, layerGroup)
            }
          },
        })
      }

      // on map click
      context.map.on('click', onMapClick)

      return {
        instance: _wmsInstance,
        context,
      }
    },
    function updateWMSTileLayer(layer, props, prevProps) {
      console.log('updateWMSTileLayer')
      if (_updateTileTimer) {
        clearTimeout(_updateTileTimer)
      }
      _updateTileTimer = setTimeout(() => {
        if (prevProps.abort && prevProps.abort.pip) {
          prevProps.abort.pip()
        }
        if (!_.isEqual(props, prevProps)) {
          updateGridLayer(layer, props, prevProps)
        }

        if (!_.isEqual(props.params, prevProps.params)) {
          layer.setParams(props.params)
        }
      }, 1000)
    }
  )
)
