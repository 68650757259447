import { Grid, IconButton, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

const useStyle = makeStyles((theme) => ({
  actionIcon: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: theme.spacing(0, 1),
    width: 30,
    height: 30,
    fontWeight: 'bold',
    fontSize: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}))

interface FormRowActionButtonsProps {
  enableAdd: boolean
  rowIndex: number
  enableRemove: boolean
  onAppend: () => void
  onRemove: (index: number) => void
}

function FormRowActionButtons({
  enableAdd,
  rowIndex,
  enableRemove,
  onAppend,
  onRemove,
}: FormRowActionButtonsProps) {
  const classes = useStyle()
  return (
    <Grid item xs={1}>
      <IconButton
        className={[classes.actionIcon].join(' ')}
        size="small"
        disabled={!enableAdd}
        style={{ visibility: enableAdd ? 'visible' : 'hidden' }}
        onClick={onAppend}
      >
        <AddIcon />
      </IconButton>
      <IconButton
        className={[classes.actionIcon].join(' ')}
        size="small"
        style={{ visibility: enableRemove ? 'visible' : 'hidden' }}
        onClick={() => onRemove(rowIndex)}
      >
        <RemoveIcon />
      </IconButton>
    </Grid>
  )
}

export default FormRowActionButtons
