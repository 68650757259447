import React from 'react'
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core'
import { useField } from 'formik'

export interface GCheckboxProps extends CheckboxProps {
  name: string
  value?: any
  label?: any
  formControlLabelProps?: Partial<FormControlLabelProps>
}

function GCheckbox({
  name,
  value,
  label,
  formControlLabelProps,
  ...props
}: GCheckboxProps) {
  const [field] = useField({ name, value, type: 'checkbox' })
  return (
    <FormControlLabel
      {...formControlLabelProps}
      control={<Checkbox {...field} {...props} />}
      label={label}
    />
  )
}

export default GCheckbox
