import { Snackbar } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSnackbar } from 'store/layout'
import { RootState } from 'store/reducer'
import AppRoutes from './AppRoutes'

export const AppWrapper = () => {
  const { snackbar } = useSelector((state: RootState) => state.layout)
  const dispatch = useDispatch()

  const onCloseSnackbar = () => {
    dispatch(toggleSnackbar({}))
  }

  return (
    <div className="app__wrapper">
      <Snackbar {...snackbar} onClose={onCloseSnackbar} />
      <AppRoutes />
    </div>
  )
}
