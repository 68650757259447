// @ts-nocheck
import React from 'react'
import MonacoEditor, { OnChange } from '@monaco-editor/react'

interface EditorProps {
  value?: string
  name?: string
  onChange?: (value: string, name: string) => void
}

export const Editor: React.FC<EditorProps> = ({
  value,
  name,
  onChange,
}: EditorProps) => {
  const onChangeHandler: OnChange = (value: string | undefined) => {
    if (onChange) {
      onChange(value, name)
    }
  }

  return (
    <MonacoEditor
      language="json"
      height={400}
      theme="vs-dark"
      value={value}
      onChange={onChangeHandler}
    />
  )
}

export default Editor
