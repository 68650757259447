import { FC } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import {
  changeRecordsView,
  createSelectPointAction,
} from 'store/layers/layer.actions'
import { FormMode } from 'global'
import useLayer from 'hooks/useLayer'

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    margin: theme.spacing(2, 0),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
}))

interface CreateRecordsButtonProps {
  active: boolean
}

const CreateRecordsButton: FC<CreateRecordsButtonProps> = ({
  active,
}: CreateRecordsButtonProps) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { userCan } = useLayer()

  const onClick = () => {
    dispatch(createSelectPointAction(''))
    dispatch(changeRecordsView(FormMode.CREATE))
  }

  if (!userCan('ADD_RECORD')) {
    return null
  }

  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx(classes.root, { [classes.hide]: !active })}
      fullWidth
      disableElevation
      onClick={onClick}
    >
      <AddIcon className={classes.icon} />
      إنشاء سجل جديد
    </Button>
  )
}
export default CreateRecordsButton
