// @ts-nocheck
import React from 'react'
import { MainButton } from '@flint/core'
import { makeStyles, Typography } from '@material-ui/core'

interface LayerCardProps {
  title: string
  text: string
  icon: any
  buttonText: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const useStyle = makeStyles((theme) => ({
  layerCard: {
    width: 300,
    height: 300,
    backgroundColor: 'white',
    boxShadow: ' 0px 7px 22px #0000001A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 10,
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  layerCardTitle: {
    color: theme.palette.primary.main,
    fontSize: 23,
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
  },
  layerCardIcon: {
    width: 52,
    height: 52,
  },
  layerCardButton: {
    fontWeight: 'bold',
    padding: theme.spacing(2, 0),
    fontSize: 20,
    borderRadius: 10,
  },
  layerCardText: {
    fontSize: 15,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
  },
}))
function CardType({ title, text, icon, buttonText, onClick }: LayerCardProps) {
  const classes = useStyle()

  const IconComponent = icon

  return (
    <div className={classes.layerCard}>
      <IconComponent className={classes.layerCardIcon} />
      <Typography className={classes.layerCardTitle}>{title}</Typography>
      <Typography className={classes.layerCardText}>{text}</Typography>
      <MainButton
        className={classes.layerCardButton}
        variant="contained"
        size="large"
        disableElevation
        fullWidth
        color="primary"
        onClick={onClick}
      >
        {buttonText}
      </MainButton>
    </div>
  )
}

export default CardType
