import { IOrganization } from 'global'
import { Dispatch } from 'redux'
import userService from 'services/user.service'
import { GetStateFn } from 'store/reducer'
import setOrgState from './org.actions'

export const fetchActiveOrg = (org: IOrganization) => {
  return async (dispatch: Dispatch, getState: GetStateFn) => {
    const orgId = org.id
    const user = getState().flint.auth.user as any
    console.log(' ', user)
    dispatch(setOrgState('loadingOrgs', true))
    try {
      const { userDetails } = await userService.fetchDetails(
        user.external_key,
        orgId
      )
      console.log('userDetails', userDetails)
      const org = userDetails.organizations[orgId]
      const { userPermissions, userRoles } = userDetails.organizations[orgId]
      dispatch(
        setOrgState('activeOrg', {
          ...org,
          permissions: userPermissions,
          roles: userRoles,
        })
      )
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setOrgState('loadingOrgs', false))
    }
  }
}

export const fetchUserOrgs = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setOrgState('loadingOrgs', true))
    try {
      const { organizationsList } = await userService.myOrgs()
      // save orgs
      dispatch(setOrgState('orgs', organizationsList))
      // get first org in the array
      let activeOrg = organizationsList[0] as IOrganization
      // get saved org id
      const orgId = localStorage.getItem('orgId')
      if (orgId) {
        const org = organizationsList.find((o: any) => o.id === orgId)
        if (org) {
          activeOrg = org
        }
      }
      // fetch acitve record
      dispatch(setOrgState('activeOrg', activeOrg))
    } catch (error) {
      console.error(error)
    } finally {
      dispatch(setOrgState('loadingOrgs', false))
    }
  }
}
