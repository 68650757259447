import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'

const useStyle = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  bg: {
    width: 20,
    height: 20,
    border: '1px solid #eee',
    marginRight: 10,
    borderRadius: 5,
    flexGrow: 0,
    flexShrink: 0,
  },
}))

function LayersLegend() {
  const { layer } = useSelector((state: RootState) => state)
  const classes = useStyle()
  const { layers, geoTechLayers } = layer
  const allLayers = useMemo(() => {
    return layers.concat(geoTechLayers)
  }, [layers, geoTechLayers])
  return (
    <Grid container spacing={1} style={{ marginTop: 20 }}>
      {allLayers.map((l) => (
        <Grid item xs={4} style={{ paddingTop: 0 }} key={l.id}>
          <div className={classes.wrapper}>
            <div
              className={classes.bg}
              style={{
                border: '1px solid',
                borderColor: l.geometryColor,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: l.geometryColor,
                  opacity: 0.2,
                }}
              />
            </div>
            <Typography style={{ fontSize: 10 }}>{l.title}</Typography>
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

export default LayersLegend
