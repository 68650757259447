import { makeStyles, Typography } from '@material-ui/core'
import { NoOrg } from 'icons'
import { Layout } from 'containers'

const useStyle = makeStyles((theme) => ({
  title: {
    color: '#000000',
    fontSize: 17,
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  mailText: {
    color: '#70b02e',
    fontWeight: 'bold',
    fontSize: 20,
    margin: theme.spacing(2, 0),
  },
  root: {
    textAlign: 'center',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBody: {
    fontSize: 15,
  },
}))

function NoOrganization() {
  const classes = useStyle()
  return (
    <Layout pageSubTitle="الخريطة التفاعلية" pageTitle="الرئيسية">
      <div className={classes.root}>
        <NoOrg />
        <Typography className={classes.title}>
          حسابكم غير مدرج تحت أي منشأة, إذا كنت مخول لإنشاء حساب لمنشأتك الرجاء
          التواصل مع فريق المبيعات:
        </Typography>
        <Typography className={classes.mailText}>contact@gt.com.sa</Typography>
        <Typography className={classes.textBody}>
          إذا كنت موظف تابع لمنشأة مسجلة في المنصة, يرجى التواصل مع مدير حسابكم
          لدعوتك
        </Typography>
      </div>
    </Layout>
  )
}

export default NoOrganization
