import { withStyles, MenuProps, Menu } from '@material-ui/core'

const GeoMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    padding: 10,
    maxHeight: 300,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export default GeoMenu
