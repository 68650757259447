import {
  Typography,
  Grid,
  FormControl,
  FormLabel,
  MenuItem,
  Divider,
  Select,
  makeStyles,
} from '@material-ui/core'
import { Fragment, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { createLayerFormAction } from 'store/layers/layer.actions'

const useStyle = makeStyles((theme) => ({
  locationPoints: {
    fontWeight: 'bold',
    margin: theme.spacing(2),
  },
}))

function LayerSchemaCoordinates() {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { layerForm } = useSelector((state: RootState) => state.layer)

  const changeLatLngField = (event: any) => {
    const { name, value } = event.target
    dispatch(
      createLayerFormAction({
        ...layerForm,
        lonLatColumns: { ...layerForm.lonLatColumns, [name]: value },
      })
    )
  }

  const changeGeoFieldType = (event: any) => {
    const { value } = event.target
    dispatch(createLayerFormAction({ ...layerForm, geoType: value }))
  }

  const changeWktField = (event: any) => {
    const { value } = event.target
    dispatch(createLayerFormAction({ ...layerForm, wktColumn: value }))
  }

  const fields = useMemo(() => {
    if (!layerForm.isFileUploaded) {
      return []
    }
    const properties = layerForm.fileUploadResponse.jsonschema.properties
    return Object.keys(properties).map((key) => key)
  }, [layerForm.fileUploadResponse, layerForm.isFileUploaded])

  return (
    <Fragment>
      <Typography className={classes.locationPoints}>
        إختر نوع و حقل الإحداثيات
      </Typography>

      <Grid container style={{ margin: '20px 0' }} spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <FormLabel>نوع الإحداثيات</FormLabel>
            <Select
              variant="outlined"
              value={layerForm.geoType}
              onChange={changeGeoFieldType}
            >
              <MenuItem value="wkt">WKT</MenuItem>
              <MenuItem value="latlng">LatLng</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            fullWidth
            style={{
              display: layerForm.geoType === 'wkt' ? 'inline-flex' : 'none',
            }}
          >
            <FormLabel>حقل الإحداثيات</FormLabel>
            <Select
              fullWidth
              variant="outlined"
              value={layerForm.wktColumn}
              onChange={changeWktField}
            >
              {fields.map((field: string) => (
                <MenuItem key={field} value={field}>
                  {field}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            style={{
              display: layerForm.geoType === 'latlng' ? 'inline-flex' : 'none',
            }}
          >
            <FormLabel>الإحداث س</FormLabel>
            <Select
              fullWidth
              variant="outlined"
              name="latitude"
              value={layerForm.lonLatColumns.latitude}
              onChange={changeLatLngField}
            >
              {fields
                .filter((field) => {
                  if (
                    layerForm.geoType === 'latlng' &&
                    field !== layerForm.lonLatColumns.longitude
                  ) {
                    return true
                  }
                  return false
                })
                .map((field: string) => (
                  <MenuItem key={field} value={field}>
                    {field}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: layerForm.geoType === 'latlng' ? 'block' : 'none',
          }}
        >
          <FormControl fullWidth>
            <FormLabel>الإحداث ص</FormLabel>
            <Select
              variant="outlined"
              name="longitude"
              value={layerForm.lonLatColumns.longitude}
              onChange={changeLatLngField}
            >
              {fields
                .filter((field: any) => {
                  if (
                    layerForm.geoType === 'latlng' &&
                    field !== layerForm.lonLatColumns.latitude
                  ) {
                    return true
                  }
                  return false
                })
                .map((field: string) => (
                  <MenuItem key={field} value={field}>
                    {field}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
    </Fragment>
  )
}

export default LayerSchemaCoordinates
