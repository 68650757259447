import { Button, makeStyles, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { FormMode, IRecord } from 'global'
import DeleteIcon from '@material-ui/icons/Delete'
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeRecordsView, setViewMode } from 'store/layers/layer.actions'
import { RootState } from 'store/reducer'

import clsx from 'clsx'

import { deleteRecord } from 'store/layers'
import useLayer from 'hooks/useLayer'
import { goToLayer } from 'utils/common'

const useStyle = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(0, 1),
    borderRadius: 15,
  },
  buttonsWrapper: {},
  icon: {
    marginRight: theme.spacing(0.5),
  },
  backWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'center',
    textAlign: 'center',
  },
  backIcon: {
    color: theme.palette.primary.main,
  },
  backText: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  hide: {
    display: 'none',
  },
}))
function RecordActions() {
  const classes = useStyle()
  const { userCan } = useLayer()
  const dispatch = useDispatch()
  const { recordFormMode, selectedLayer, selectdRecord, loadingLayers } =
    useSelector((state: RootState) => state.layer)
  const ActionButton = useMemo(
    () =>
      function ({ Icon, text, ...rest }: any) {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            className={classes.button}
            {...rest}
          >
            <Icon fontSize="small" className={classes.icon} />
            {text}
          </Button>
        )
      },
    [classes]
  )

  const onDelete = async () => {
    const _record = selectdRecord as IRecord
    const { id: layerId, geometryType } = selectedLayer
    dispatch(deleteRecord(_record.id, geometryType, layerId))
  }

  // on click back
  const onBackClick = () => {
    goToLayer(selectedLayer)
    dispatch(setViewMode())
  }

  const onEdit = () => {
    dispatch(changeRecordsView(FormMode.EDIT))
  }

  return (
    <div>
      <div
        className={clsx(classes.buttonsWrapper, {
          [classes.hide]: recordFormMode === FormMode.CREATE,
        })}
      >
        {userCan('EDIT_RECORD') && (
          <ActionButton
            Icon={EditIcon}
            text="تعديل"
            onClick={onEdit}
            disabled={recordFormMode === FormMode.EDIT || loadingLayers}
          />
        )}
        {userCan('DELETE_RECORD') && (
          <ActionButton
            Icon={DeleteIcon}
            text="حذف"
            onClick={onDelete}
            disabled={loadingLayers}
          />
        )}
      </div>
      <Button
        className={classes.backWrapper}
        fullWidth
        onClick={onBackClick}
        disabled={loadingLayers}
      >
        <Typography className={classes.backText}>عودة الى البيانات</Typography>
        <LeftArrowIcon className={classes.backIcon} />
      </Button>
    </div>
  )
}

export default RecordActions
