// @ts-nocheck
import React from 'react'
import { Button, MenuItem } from '@material-ui/core'
import { GeoMenu } from 'components/GeoMenu'
import { useDispatch, useSelector } from 'react-redux'
import {
  setActiveLayer,
  createSelectPointAction,
  changeRecordsView,
  setActiveRecord,
  setLocationForUpdate,
} from 'store/layers/layer.actions'
import { ILayer, FormMode } from 'global'
import { RootState } from 'store/reducer'
import clsx from 'clsx'
import useStyle from './AddToLayerMenu.style'

const { stringify } = require('wkt')

function AddToLayerMenu() {
  const classes = useStyle()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { changeLocationMode, layers, selectedGeoJson, openedPopup, lfLayer } =
    useSelector((state: RootState) => state.layer)
  const dispatch = useDispatch()

  const setSelectedLayer = (layer: ILayer) => {
    const wkt = stringify(selectedGeoJson)
    dispatch(createSelectPointAction(wkt))
    dispatch(setActiveLayer(layer))
    dispatch(changeRecordsView(FormMode.CREATE))
  }

  const addToLayer = () => {
    const wkt = stringify(selectedGeoJson)
    dispatch(createSelectPointAction(wkt))
    if (!changeLocationMode) {
      dispatch(setActiveRecord({}))
      dispatch(changeRecordsView(FormMode.CREATE))
    } else {
      dispatch(setLocationForUpdate(wkt))
      lfLayer.remove()
      openedPopup.remove()
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.popupRoot}>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={addToLayer}
        className={clsx(classes.popupAddToLayer)}
      >
        {changeLocationMode ? 'إضافة للسجل' : 'إضافة للطبقة'}
      </Button>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
        disableElevation
        className={clsx(classes.popupAnotherButton)}
      >
        إضافة إلى طبقة إخري
      </Button>
      <GeoMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {layers.map((layer) => (
          <MenuItem key={layer.title} onClick={() => setSelectedLayer(layer)}>
            {layer.title}
          </MenuItem>
        ))}
      </GeoMenu>
    </div>
  )
}

export default AddToLayerMenu
