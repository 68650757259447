import { Grid, Button } from '@material-ui/core'
import { useFormikContext } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { CreateLayerRequest, SchemaBuilderViews } from 'global'

import {
  createLayerAction,
  createLayerFormAction,
} from 'store/layers/layer.actions'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'store/reducer'
import useJsonSchemaMapper from 'hooks/useJsonSchemaMapper'
import {
  arrPropsToObj,
  getArrayOfStrings,
  getSummarizedProps,
  isAdvancedSchema,
} from 'utils/jsonSchema'
import BuilderViews from './BuilderViews'
import LayerSchemaHeader from './LayerSchemaHeader'
import UiView from './BuilderViews/UiView'
import CodeEditor from './BuilderViews/CodEditor'
import Playground from './BuilderViews/Playground'

const layerSchemaTabs = [
  {
    tab: SchemaBuilderViews.UI_VIEW,
    label: 'واجهة المستخدم',
    Component: UiView,
  },
  {
    tab: SchemaBuilderViews.CODE_EDITOR,
    label: 'محرر متقدم',
    Component: CodeEditor,
  },
  {
    tab: SchemaBuilderViews.PLAYGROUND,
    label: 'عرض',
    Component: Playground,
  },
]

function LayerSchema() {
  const dispatch = useDispatch()
  const { layerForm } = useSelector((state: RootState) => state.layer)
  const [activeTab, setTab] = useState<SchemaBuilderViews>(
    SchemaBuilderViews.UI_VIEW
  )
  const { mapJsonSchemaObjToArr } = useJsonSchemaMapper()

  const moveBack = () => {
    dispatch(createLayerFormAction({ isActive: true, step: 1 }))
  }
  const { values, submitForm, setFieldValue, errors } =
    useFormikContext<CreateLayerRequest>()
  const { jsonSchema } = values

  const summaryFieldLength = useMemo(() => {
    const props = []
    getSummarizedProps(arrPropsToObj(jsonSchema), (h) => {
      console.log('getSummarizedProps callback', h)
      props.push(h)
    })

    return props.length
  }, [jsonSchema])

  const isAdvancedJsonSchema = useMemo(() => {
    return isAdvancedSchema(jsonSchema)
  }, [jsonSchema])

  useEffect(() => {
    if (activeTab === SchemaBuilderViews.UI_VIEW && isAdvancedJsonSchema) {
      setTab(SchemaBuilderViews.CODE_EDITOR)
    }
  }, [activeTab, isAdvancedJsonSchema])

  useEffect(() => {
    dispatch(createLayerAction('isAdvancedSchema', isAdvancedJsonSchema))
  }, [isAdvancedJsonSchema, dispatch])

  const errorMessages = useMemo(() => {
    let summaryLengthMsg = ''
    const errs = []
    // summary length messages
    if (!summaryFieldLength) {
      summaryLengthMsg = 'يجب إختيار حقل واحد على الأقل ليكون في الملخلص'
    } else if (summaryFieldLength > 6) {
      summaryLengthMsg = 'غير مسموح بإضافة أكثر من ٦ حقول إلى الملخص'
    }

    if (summaryLengthMsg) {
      errs.push(summaryLengthMsg)
    }

    // validation err
    const validationErrors = getArrayOfStrings(errors)
    console.log('validationErrors', validationErrors)
    if (validationErrors.length && !isAdvancedJsonSchema) {
      errs.push(validationErrors[0])
    }

    return errs.length ? errs : null
  }, [summaryFieldLength, errors, isAdvancedJsonSchema])

  useEffect(() => {
    const isDataset = layerForm.fileUploadResponse && layerForm.isFileUploaded
    if (isDataset) {
      const updatedJsonSchema = mapJsonSchemaObjToArr(
        layerForm.fileUploadResponse.jsonschema
      )
      setFieldValue('jsonSchema', updatedJsonSchema)
    }
  }, [layerForm, setFieldValue, mapJsonSchemaObjToArr])

  const disableSubmitButton =
    !summaryFieldLength || summaryFieldLength > 6 || !!errorMessages

  return (
    <div style={{ minWidth: '100%' }}>
      <LayerSchemaHeader errorMessage={errorMessages} />

      <BuilderViews
        isAdvancedSchema={isAdvancedJsonSchema}
        tabs={layerSchemaTabs}
        activeTab={activeTab}
        onTabChange={setTab}
      />

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            size="large"
            disableElevation
            onClick={submitForm}
            disabled={disableSubmitButton}
          >
            حفظ
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            size="large"
            disableElevation
            onClick={moveBack}
          >
            رجوع
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default LayerSchema
